<template>
<div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
  <div
    class='background-style text-white py-4 px-4 lg:py-16 lg:px-16 flex-shrink-0 w-full lg:w-96'
    :style='`${backgroundStyle}`'>
    <h2 class='text-2xl uppercase'>Receipt Lookup</h2>
    <h3 class='text-normal mt-2 leading-5'>Please enter in the license number and name to lookup paid reciepts.</h3>
  </div>
  <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
    <div class='bg-gray-50 p-8 pb-12 rounded-md border'>
      <h2 class='text-xl font-semibold'>Lookup Receipt</h2>
      <div class='my-4'>
        <label class='uppercase text-gray-600 mb-2 block'>License Number</label>
        <div><input v-model='licenseField' type='text' class='border rounded py-2 px-2 w-96'/></div>
      </div>
      <div class='my-4'>
        <label class='uppercase text-gray-600 mb-2 block'>Name (국문)</label>
        <div><input v-model='nameField' type='text' class='border rounded py-2 px-2 w-96'/></div>
      </div>
      <div><button class='w-96 py-4 bg-gray-900 text-white uppercase rounded hover:shadow-md' @click='addLookup'>Lookup</button></div>
    </div>
    <div class='mt-8 text-base'>
      <table class='w-full table-fixed'>
        <thead>
          <tr class='border-b'>
            <th class='py-2 pr-2 uppercase text-left'>License Number</th>
            <th class='py-2 px-2 uppercase text-left'>Name</th>
            <th class='py-2 pl-2 uppercase text-right w-1/2'>download</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(receipt, index) in receiptLookups'
            :key='`receipt${index}-for-${receipt.licenseNumber}`'>
            <td class='py-2 pr-2 text-left'>{{receipt.licenseNumber}}</td>
            <td class='py-2 px-2 text-left'>{{receipt.name}}</td>
            <td class='py-2 pl-2 text-right'>
              <span v-if='receipt.payStatus === "polling"' class=''>
                <loader-icon class='spinner inline-block' /> Checking
              </span>
              <router-link
                v-else-if='receipt.payStatus === "paid"'
                :to='{ name: "ReceiptDownloadFile", 
                       query: {
                        "licenseNumber": receipt.licenseNumber,
                        "name": receipt.name
                      } }'
                target='_blank'
                class='hover:underline text-blue-700'>
                Receipt Download
              </router-link>
              <span v-else-if='receipt.payStatus === "unpaid"' class='text-red-600'>has not paid yet</span>
              <span v-else class='text-red-600'>please check the license number or name</span>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colspan='3'>
              <div></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { LoaderIcon } from 'vue-tabler-icons'

export default {
  name: 'ReceiptDownload',
  components: {
    LoaderIcon,
  },
  data () {
    return {
      licenseField: '',
      nameField: '',
      receiptLookups: []
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventImageS3Path',
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
  },
  methods: {
    ...mapActions('users', [
      'getPaidRegistrationDetails',
    ]),
    addLookup () {
      let newReceipt = {
        'licenseNumber': this.licenseField,
        'name': this.nameField,
        'payStatus': 'polling'
      }
      
      this.receiptLookups.push(newReceipt)
      let index = this.receiptLookups.length - 1
      
      this.resetFields()
      this.getPaidRegistrationDetails({
        'licenseNumber': this.receiptLookups[index].licenseNumber,
        'name':          this.receiptLookups[index].name
      }).then(resp => {
        if (resp.participantUnknown) {
           this.receiptLookups[index]['payStatus'] = 'unknown'
        } else if (resp.registrationData.payment) {
           this.receiptLookups[index]['payStatus'] = 'paid'
        } else {
           this.receiptLookups[index]['payStatus'] = 'unpaid'
        }
      })
    },
    resetFields () {
      this.licenseField = ''
      this.nameField    = ''
    },
  },
}
</script>

<style lang='scss'>
  .certificate-print {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
</style>

<style lang='scss' scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>